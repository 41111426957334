<template>
  <!-- Active Users Card -->
  <a-card :bordered="false" class="dashboard-bar-chart">
    <chart-bar
      :height="220"
      :data="lang == 'uz' ? barChartData : barChartDataRu"
    ></chart-bar>
    <div class="card-title">
      <h6>{{ lang == "uz" ? "Bemor bolalar" : "Больные дети" }}</h6>
      <p>
        {{
          lang == "uz"
            ? "o'tgan haftaga nisbatan"
            : "по сравнению с прошлой неделей"
        }}
        <span class="text-success">+21%</span>
      </p>
    </div>
    <div class="card-content">
      <p>
        {{
          lang == "uz"
            ? "Bir hafta davomida ko'rikdan o'tgan va kasallangan bolalar soni."
            : "Количество детей, обследованных и инфицированных за неделю."
        }}
      </p>
    </div>
    <a-row class="card-footer" type="flex" justify="center" align="top">
      <a-col :span="6">
        <h4>+3300</h4>
        <span>{{ lang == "uz" ? "Jami" : "Общий" }}</span>
      </a-col>
      <a-col :span="6">
        <h4>+3000</h4>
        <span>{{ lang == "uz" ? "Tuzalgan" : "Исправленный" }}</span>
      </a-col>
      <a-col :span="6">
        <h4>+280</h4>
        <span>{{ lang == "uz" ? "Davolanyapti" : "Лечат" }}</span>
      </a-col>
      <a-col :span="6">
        <h4>+180</h4>
        <span>{{ lang == "uz" ? "Tekshirilmoqda" : "Проверка" }}</span>
      </a-col>
    </a-row>
  </a-card>
  <!-- Active Users Card -->
</template>

<script>
// Bar chart for "Active Users" card.
import ChartBar from "../Charts/ChartBar";

export default {
  components: {
    ChartBar,
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "uz",
      // Data for bar chart.
      barChartData: {
        labels: [
          "Dushanba",
          "Seshanba",
          "Chorshanba",
          "Payshanba",
          "Juma",
          "Shanba",
          "Yakshanba",
        ],
        datasets: [
          {
            label: "Bolalar",
            backgroundColor: "#fff",
            borderWidth: 0,
            borderSkipped: false,
            borderRadius: 6,
            data: [55, 78, 121, 30, 44, 89, 27, 99, 124],
            maxBarThickness: 20,
          },
        ],
      },
      barChartDataRu: {
        labels: [
          "Понедельник",
          "Вторник",
          "Среда",
          "Четверг",
          "Пятница",
          "Суббота",
          "Воскресенье",
        ],
        datasets: [
          {
            label: "Дети",
            backgroundColor: "#fff",
            borderWidth: 0,
            borderSkipped: false,
            borderRadius: 6,
            data: [55, 78, 121, 30, 44, 89, 27, 99, 124],
            maxBarThickness: 20,
          },
        ],
      },
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
};
</script>