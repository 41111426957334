<template>
  <a-card :bordered="false" class="dashboard-bar-line header-solid">
    <template #title>
      <h6>{{ lang == "uz" ? "Bemorlar soni" : "Количество пациентов" }}</h6>
      <p>
        {{
          lang == "uz"
            ? "oxirgi yilga nisbatan"
            : "по сравнению с прошлым годом"
        }}
        <span class="text-success">+40%</span>
      </p>
    </template>
    <template #extra>
      <a-badge
        color="primary"
        class="badge-dot-primary"
        :text="lang == 'uz' ? 'Erkaklar' : 'Мужчины'"
      />
      <a-badge
        color="primary"
        class="badge-dot-secondary"
        :text="lang == 'uz' ? 'Ayollar' : 'Женщины'"
      />
    </template>
    <chart-line
      :height="310"
      :data="lang == 'uz' ? lineChartData : lineChartDataRu"
    ></chart-line>
  </a-card>
</template>

<script>
// Bar chart for "Active Users" card.
import ChartLine from "../Charts/ChartLine";

export default {
  components: {
    ChartLine,
  },
  data() {
    return {
      lang: null,
      // Data for line chart.
      lineChartData: {
        labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Mobile apps",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#1890FF",
            borderWidth: 3,
            data: [150, 240, 2500, 1220, 600, 6400, 3250, 9230, 10500],
            maxBarThickness: 6,
          },
          {
            label: "Websites",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#B37FEB",
            borderWidth: 3,
            data: [130, 390, 840, 3290, 2140, 6230, 5340, 4290, 7400],
            maxBarThickness: 6,
          },
        ],
      },
      lineChartDataRu: {
        labels: ["Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
        datasets: [
          {
            label: "Mobile apps",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#1890FF",
            borderWidth: 3,
            data: [150, 240, 2500, 1220, 600, 6400, 3250, 9230, 10500],
            maxBarThickness: 6,
          },
          {
            label: "Websites",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            borderColor: "#B37FEB",
            borderWidth: 3,
            data: [130, 390, 840, 3290, 2140, 6230, 5340, 4290, 7400],
            maxBarThickness: 6,
          },
        ],
      },
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
};
</script>